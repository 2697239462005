import React,{useEffect,useState} from 'react';
import { Layout } from 'antd';

import Header from '../components/shared/Header';
import Footer from '../components/shared/Footer';
import { COUNTRY,COUNTRY2 } from '../constants/constants';

const AuthLayout = (props) => {
  const { children, history } = props;
  const [currentCountry, setCurrentCountry] = useState("")

  useEffect(()=>{
    if (true) {
    }
    
  },[])

  return (
    <Layout>
      <>
      <Header history={history}/>
      {children}
      <Footer history={history}/>
      </>
    </Layout>
  );
}

export default AuthLayout;
