import React, { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { CriticalCSSProvider, StyleRegistry } from "react-critical-css"; // <-

// import AppRoute from './routes/AppRoute';
import AuthenticationRoute from "./routes/AuthenticationRoute";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

const AppRoute = lazy(() => import("./routes/AppRoute"));

import { Provider } from "react-redux";

import store from "./store";

const App = () => {
  const analytics = Analytics({
    app: "awesome-app",
    plugins: [
      googleAnalytics({
        measurementIds: ["G-KDEQQ04Z37"],
      }),
    ],
  });
  analytics.page();
  const styleRegistry = new StyleRegistry(); // create new style registry

  return (
    <>
      <Provider store={store}>
        <Router>
          <Switch>
            <ProtectedRoutes path="/user" />
            <AuthenticationRoute path="/auth" />
            <Suspense fallback={<></>}>
              <CriticalCSSProvider registry={styleRegistry}>
                <AppRoute path="/" />
              </CriticalCSSProvider>
            </Suspense>
          </Switch>
        </Router>
      </Provider>
    </>
  );
};

export default App;
