import { combineReducers } from 'redux';

import sport from './sport';
import sportDetails from './sport-details';
import sportListings from './sport-listings';
import sportDonate from './sport-donate';

export default combineReducers({
  sport,
  sportDetails,
  sportListings,
  sportDonate
});
