import React from 'react';
import { connect } from 'react-redux';

import EventCreationComponent from '../../components/app/Events/eventCreation/EventCreation';

import * as actions from '../../actions/events/eventCreation';

const EventCreation = (props) => {
  return (
    <EventCreationComponent {...props} />
  );
};

export default connect(({ event }) => event.eventCreation, actions)(EventCreation);
