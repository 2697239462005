const initialState = {
  loading: false,
  charity: {}
};

const charityDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FEATURED_CHARITIES_SUCCESS':
    case 'GET_FEATURED_CHARITIES_FAILED':
    case 'GET_FEATURED_CHARITIES_REQUEST':
    case 'SHARE_CHARITY_REQUEST':
    case 'SHARE_CHARITY_SUCCESS':
    case 'SHARE_CHARITY_FAILED':
    case 'SUBSCRIBE_CHARITY_DETAILS_REQUEST':
    case 'SUBSCRIBE_CHARITY_DETAILS_FAILED':
    case 'SUBSCRIBE_CHARITY_DETAILS_SUCCESS':
    case 'LIKE_COMPANY_CHARITY_REQUEST':
    case 'LIKE_COMPANY_CHARITY_SUCCESS':
    case 'LIKE_COMPANY_CHARITY_FAILED':
    case 'FOLLOW_COMPANY_CHARITY_DETAILS_REQUEST':
    case 'FOLLOW_COMPANY_CHARITY_DETAILS_FAILED':
    case 'FOLLOW_COMPANY_CHARITY_DETAILS_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_CHARITY_DETAILS_REQUEST':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_CHARITY_DETAILS_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_CHARITY_DETAILS_FAILED':
    case 'ENQUIRE_QUERY_CHARITY_FORM_SUCCESS':
    case 'ENQUIRE_QUERY_CHARITY_FORM_REQUEST':
    case 'ENQUIRE_QUERY_CHARITY_FORM_FAILED':
    case 'GET_CUSTOMER_REVIEWS_FOR_CHARITY_DETAILS_REQUEST':
    case 'GET_CUSTOMER_REVIEWS_FOR_CHARITY_DETAILS_SUCCESS':
    case 'GET_CUSTOMER_REVIEWS_FOR_CHARITY_DETAILS_FAILED':
    case 'GET_CHARITY_BY_ID_FOR_CHARITY_DETAILS_PAGE_REQUEST':
    case 'GET_CHARITY_BY_ID_FOR_CHARITY_DETAILS_PAGE_FAILED':
    case 'GET_CHARITY_BY_ID_FOR_CHARITY_DETAILS_PAGE_SUCCESS': {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default charityDetails;
