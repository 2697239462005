const initialState = {
  loading: false,
  community: {}
};

const communityDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FEATURED_COMMUNITY_SUCCESS':
    case 'GET_FEATURED_COMMUNITY_FAILED':
    case 'GET_FEATURED_COMMUNITY_REQUEST':
    case 'SHARE_COMMUNITY_REQUEST':
    case 'SHARE_COMMUNITY_SUCCESS':
    case 'SHARE_COMMUNITY_FAILED':
    case 'SUBSCRIBE_COMMUNITY_DETAILS_REQUEST':
    case 'SUBSCRIBE_COMMUNITY_DETAILS_FAILED':
    case 'SUBSCRIBE_COMMUNITY_DETAILS_SUCCESS':
    case 'LIKE_COMPANY_COMMUNITY_REQUEST':
    case 'LIKE_COMPANY_COMMUNITY_SUCCESS':
    case 'LIKE_COMPANY_COMMUNITY_FAILED':
    case 'FOLLOW_COMPANY_COMMUNITY_DETAILS_REQUEST':
    case 'FOLLOW_COMPANY_COMMUNITY_DETAILS_FAILED':
    case 'FOLLOW_COMPANY_COMMUNITY_DETAILS_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_COMMUNITY_DETAILS_REQUEST':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_COMMUNITY_DETAILS_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_COMMUNITY_DETAILS_FAILED':
    case 'ENQUIRE_QUERY_COMMUNITY_FORM_REQUEST':
    case 'ENQUIRE_QUERY_COMMUNITY_FORM_FAILED':
    case 'ENQUIRE_QUERY_COMMUNITY_FORM_SUCCESS':
    case 'GET_CUSTOMER_REVIEWS_FOR_COMMUNITY_DETAILS_REQUEST':
    case 'GET_CUSTOMER_REVIEWS_FOR_COMMUNITY_DETAILS_SUCCESS':
    case 'GET_CUSTOMER_REVIEWS_FOR_COMMUNITY_DETAILS_FAILED':
    case 'GET_COMMUNITY_BY_ID_FOR_COMMUNITY_DETAILS_PAGE_SUCCESS':
    case 'GET_COMMUNITY_BY_ID_FOR_COMMUNITY_DETAILS_PAGE_FAILED':
    case 'GET_COMMUNITY_BY_ID_FOR_COMMUNITY_DETAILS_PAGE_REQUEST': {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default communityDetails;
