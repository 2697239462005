import { combineReducers } from 'redux';

import charity from './charity';
import charityDonate from './charity-donate';
import charityListings from './charity-listings';
import charityDetails from './charity-details';

export default combineReducers({
  charityListings,
  charityDonate,
  charity,
  charityDetails
});
