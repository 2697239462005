const initialState = {
  loading: false,
  sport: {}
};

const sportDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FEATURED_ORGANIZATION_SUCCESS':
    case 'GET_FEATURED_ORGANIZATION_FAILED':
    case 'GET_FEATURED_ORGANIZATION_REQUEST':
    case 'SHARE_ORGANIZATION_REQUEST':
    case 'SHARE_ORGANIZATION_SUCCESS':
    case 'SHARE_ORGANIZATION_FAILED':
    case 'SUBSCRIBE_ORGANIZATION_DETAILS_REQUEST':
    case 'SUBSCRIBE_ORGANIZATION_DETAILS_FAILED':
    case 'SUBSCRIBE_ORGANIZATION_DETAILS_SUCCESS':
    case 'LIKE_COMPANY_ORGANIZATION_REQUEST':
    case 'LIKE_COMPANY_ORGANIZATION_SUCCESS':
    case 'LIKE_COMPANY_ORGANIZATION_FAILED':
    case 'FOLLOW_COMPANY_ORGANIZATION_DETAILS_REQUEST':
    case 'FOLLOW_COMPANY_ORGANIZATION_DETAILS_FAILED':
    case 'FOLLOW_COMPANY_ORGANIZATION_DETAILS_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_ORGANIZATION_DETAILS_REQUEST':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_ORGANIZATION_DETAILS_SUCCESS':
    case 'GET_PEOPLE_NEEDED_FOR_HELP_FOR_ORGANIZATION_DETAILS_FAILED':
    case 'ENQUIRE_QUERY_ORGANIZATION_FORM_REQUEST':
    case 'ENQUIRE_QUERY_ORGANIZATION_FORM_FAILED':
    case 'ENQUIRE_QUERY_ORGANIZATION_FORM_SUCCESS':
    case 'GET_CUSTOMER_REVIEWS_FOR_ORGANIZATION_DETAILS_REQUEST':
    case 'GET_CUSTOMER_REVIEWS_FOR_ORGANIZATION_DETAILS_SUCCESS':
    case 'GET_CUSTOMER_REVIEWS_FOR_ORGANIZATION_DETAILS_FAILED':
    case 'GET_ORGANIZATION_BY_ID_FOR_ORGANIZATION_DETAILS_PAGE_REQUEST':
    case 'GET_ORGANIZATION_BY_ID_FOR_ORGANIZATION_DETAILS_PAGE_FAILED':
    case 'GET_ORGANIZATION_BY_ID_FOR_ORGANIZATION_DETAILS_PAGE_SUCCESS': {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default sportDetails;
